import React                  from 'react';
import PropTypes              from 'prop-types';
import OpenMessengerWrapper   from './ContactContainer/OpenMessengerWrapper';
import PinActorButtonWrapper  from './ContactContainer/PinActorButtonWrapper';
import Flux                   from '../../../flux/Flux';
import VideochatButtonWrapper from '../../SimpleElements/VideochatButtonWrapper';
import { generateKey }        from '../../../utils/CommonUtils';

const ActorContactContainer = ({
    show,
    isOnline,
    isGuestLoggedIn,
    isActorPinned,
    hasLivePreview,
    sublineText,
    actorId,
    openMessenger,
    isOnlineMobileVideocall
}) => {
    if (!show) {
        return null;
    }

    const btns = [];

    if (isOnline) {

        btns.push(
            <VideochatButtonWrapper
                key     = {generateKey('actor-tile-button', btns.length)}
                type    = {Flux.Constants.VideochatButtonTypes.actorTile}
                actorId = {actorId}
            />
        );
        if (hasLivePreview) {
            btns.push(
                <VideochatButtonWrapper
                    key     = {generateKey('actor-tile-button', btns.length)}
                    type    = {Flux.Constants.VideochatButtonTypes.actorProfileLivePreviewSmall}
                    actorId = {actorId}
                />
            );
        }
    }

    const addPinButton = (
        !isActorPinned // actor is not pinned
        && (!isOnline && !isOnlineMobileVideocall) // actor is offline
        && btns.length < 2 // there are no other buttons
    );
    if (addPinButton) {
        btns.push(
            <PinActorButtonWrapper
                key        = {generateKey('actor-tile-button', btns.length)}
                show       = {true}
                actorId    = {actorId}
                isLoggedIn = {isGuestLoggedIn}
                isPinned   = {isActorPinned}
            />
        );
    }

    if (btns.length < 2) {
        btns.push(
            <OpenMessengerWrapper
                key           = {generateKey('actor-tile-button', btns.length)}
                show          = {!isOnline || !hasLivePreview}
                openMessenger = {openMessenger}
            />
        );
    }

    return (
        <div className="actorcontact__container">
            <div className="actorcontact">
                <div className="actorcontact__text">
                    <span className="actorcontact__text-actorname">{sublineText}</span>
                </div>
                <div className={"actorcontact__container--btn-wrapper" + (isOnlineMobileVideocall ? " actorcontact__container--btn-wrapper--margin" : "")}>
                    {btns}
                </div>
            </div>
        </div>
    );
};

ActorContactContainer.propTypes = {
    show:                    PropTypes.bool,
    isOnline:                PropTypes.bool,
    isGuestLoggedIn:         PropTypes.bool,
    isActorPinned:           PropTypes.bool,
    hasLivePreview:          PropTypes.bool,
    sublineText:             PropTypes.string,
    multiChatPrice:          PropTypes.number,
    singleC2CChatPrice:      PropTypes.number,
    actorId:                 PropTypes.number,
    openMessenger:           PropTypes.func,
    isOnlineMobileVideocall: PropTypes.bool,
};

ActorContactContainer.defaultProps = {
    show:                    false,
    isOnline:                false,
    isGuestLoggedIn:         false,
    isActorPinned:           false,
    sublineText:             '',
    isOnlineMobileVideocall: false,
};

export default ActorContactContainer;
